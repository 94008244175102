<template>
  <div class="container join">
    <div class="baseBox">
      <div class="joinForm">
        <p>
          현재 비밀번호
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="password" />
        </div>
        <p>
          신규 비밀번호
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="newPassword" />
        </div>
        <p>
          신규 비밀번호 확인
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="passwordConfirm" />
        </div>

        <button class="point full" @click="submit()">변경하기</button>
      </div>
    </div>
  </div>
</template>
<script>
import { updateUserPwd } from "@/api/index";
import { mapState } from "vuex";

export default {
  data() {
    return {
      password: "",
      passwordConfirm: "",
      newPassword: "",
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  created() {
    this.$store.dispatch("SET_PAGE_NAME", "비밀번호변경");
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    submit() {
      if (this.password == "") {
        this.openAlert("현재 비밀번호를 입력해주세요.");
        return;
      } else if (this.newPassword == "") {
        this.openAlert("신규 비밀번호를 입력해주세요.");
        return;
      } else if (this.passwordConfirm != this.newPassword) {
        this.openAlert("비밀번호를 다시 입력해주세요.");
        return;
      }

      let data = {
        password: this.password,
        newPassword: this.newPassword,
      };
      updateUserPwd(data).then((res) => {
        if (res.data.status == 200) {
          this.password = "";
          this.newPassword = "";
          this.passwordConfirm = "";
          this.openAlert("비밀번호가 변경 되었습니다.");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
